import { useMutation, useQueryClient } from "@tanstack/react-query";

import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { updateCurrentVenueMutationVariables } from "~/types/__generated/gql/graphql";
import {
	GetCurrentVenueResults,
	getCurrentVenueQueryKey,
} from "../useGetCurrentVenue";

const query = /* GraphQL */ `
	mutation updateCurrentVenue($input: UpdateCurrentVenueInput!) {
		updateCurrentVenue(input: $input) {
			_id
			created_at
			updated_at
			name
			description
			address
			timezone
			logo
			banner_img
			is_self_served
			allow_comments
			item_types
			cancelled_reason_options
			custom_payment_types {
				label
				value
				is_deactivated
			}
			shifts {
				title
				startTime
				endTime
			}
			auto_print_checklist
			currencies_configs {
				code
				rate
				previous_rate
				is_primary_currency
				is_secondary_currency
				is_deactivated
			}
			fcm_tokens
			is_service_charge_buried
			service_charge
			is_vat_buried
			vat
			theme {
				primary_color
				primary_text_color
			}
			is_ordering_requires_customer
			is_qr_ordering_only
			custom_note_instructions
			is_ordering_requires_note
			order_notification_dings
			ops_page_font_size
			is_payway_integration_enabled
			is_internal_receipt_enabled
			is_language_localization_enabled
			is_required_headcount_before_payment
			is_payment_confirmation_enabled
			is_accounting_journal_enabled
			is_room_master_integration_enabled
			room_master_property_id
			custom_printing_fields {
				label
			}
			vat_tin
			preset_messages
			is_inventory_management_enabled
			is_custom_report_enabled
			is_payment_rounding_enabled
			available_printers {
				name
			}
			stations {
				_id
				printer_name
				station_name
				types_of_interest
				locations_of_interest
				printer_tags_of_interest
			}
			print_content_padding
			print_font_sizes {
				ticket
				receipt
				reports
			}
			operational_data_refetch_interval
			is_print_ticket_by_item
			is_print_ticket_by_option
			is_grouping_option_on_print_ticket
			should_aggregate_tips_into_total_gross_sales
			is_dual_screen_mode_enable
			default_ops_page
			is_till_send_and_pay_enabled
			is_till_send_enabled
			is_kpay_integration_enabled
		}
	}
`;

export const getUpdateCurrentVenueMutationKey = () => ["updateCurrentVenue"];

export const useUpdateCurrentVenue = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: getUpdateCurrentVenueMutationKey(),
		mutationFn: async (args: updateCurrentVenueMutationVariables) =>
			hubGqlClient
				.request(graphql(query), args)
				.then((res) => res.updateCurrentVenue),
		onSuccess: (data) => {
			queryClient.setQueryData<GetCurrentVenueResults>(
				getCurrentVenueQueryKey(),
				(prev) => {
					if (!prev) return prev;

					return { ...prev, ...data };
				},
			);
		},
	});
};
