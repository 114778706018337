export const tokens = {
	sidePanel: {
		ops: "sidePanel.ops",
		inventories: "sidePanel.inventories",
		menus: "sidePanel.menus",
		receipts: "sidePanel.receipts",
		orders: "sidePanel.orders",
		reports: "sidePanel.reports",
		customers: "sidePanel.customers",
		offers: "sidePanel.offers",
		purchase: "sidePanel.purchase",
		settings: "sidePanel.settings",
		openCashdrawer: "sidePanel.openCashdrawer",
	},
	searchBar: {
		orderNumber: "ops.searchBar.orderNumber",
		locationName: "ops.searchBar.locationName",
		itemName: "ops.searchBar.itemName",
	},
	nav: {
		till: "nav.till",
		orders: "nav.orders",
		locations: "nav.locations",
		kds: "nav.kds",
		reservations: "nav.reservation",
	},
	ops: {
		orders: {
			placed: "ops.orders.placed",
			preparing: "ops.orders.preparing",
			processed: "ops.orders.processed",
			unpaid: "ops.orders.unpaid",
			paid: "ops.orders.paid",
			d: "ops.orders.d",
			h: "ops.orders.h",
			items: "ops.orders.items",
			cancelledItems: "ops.orders.cancelledItems",
			of: "ops.orders.of",
			prepared: "ops.orders.prepared",
			orderCreated: "ops.orders.orderCreated",
			movedToPreparing: "ops.orders.movedToPreparing",
			customerAcceptPrepTime: "ops.orders.customerAcceptPrepTime",
			customerRejectPrepTime: "ops.orders.customerRejectPrepTime",
			prepTimeUpdate: "ops.orders.prepTimeUpdate",
			autoCancelledAllItems: "ops.orders.autoCancelledAllItems",
			autoCancelledAllSplitItems: "ops.orders.autoCancelledAllSplitItems",
			autoCancelledAllMovedItems: "ops.orders.autoCancelledAllMovedItems",
			quantityChangeFrom: "ops.orders.quantityChangeFrom",
			quantityChangeTo: "ops.orders.quantityChangeTo",
			cancelled: "ops.orders.cancelled",
			discountChangeFrom: "ops.orders.discountChangeFrom",
			discountChangeTo: "ops.orders.discountChangeTo",
			splitOf: "ops.orders.splitOf",
			splitAll: "ops.orders.splitAll",
			splited: "ops.orders.splited",
			moveOf: "ops.orders.moveOf",
			moveAll: "ops.orders.moveAll",
			moved: "ops.orders.moved",
			movedToProcessed: "ops.orders.movedToProcessed",
			markedAsPaid: "ops.orders.markedAsPaid",
			orderCancelled: "ops.orders.orderCancelled",
			add: "ops.orders.add",
			edit: "ops.orders.edit",
			takePayment: "ops.orders.takePayment",
			processOrder: "ops.orders.processOrder",
			prepareOrder: "ops.orders.prepareOrder",
			printKitchenMessage: "ops.orders.printKitchenMessage",
			printBill: "ops.orders.printBill",
			printTicket: "ops.orders.printTicket",
			printChecklist: "ops.orders.printChecklist",
			printCancelledTicket: "ops.orders.printCancelledTicket",
			kitchenMessageContent: "ops.orders.kitchenMessageContent",
			messageIsRequired: "ops.orders.messageIsRequired",
			cancelOrder: "ops.orders.cancelOrder",
			cancelOrderModel: "ops.orders.cancelOrderModel",
			cancelOrderModelDesc: "ops.orders.cancelOrderModelDesc",
			subTotal: "ops.orders.subTotal",
			offer: "ops.orders.offer",
			discount: "ops.orders.discount",
			vat: "ops.orders.vat",
			service: "ops.orders.service",
			adjustment: "ops.orders.adjustment",
			grandTotal: "ops.orders.grandTotal",
			locationName: "ops.orders.locationName",
		},
		locations: {
			showAllLocation: "ops.locations.showAllLocation",
			only: "ops.locations.only",
			showing: "ops.locations.showing",
			locations: "ops.locations.locations",
			switchToListView: "ops.locations.switchToListView",
			switchToFloorPlan: "ops.locations.switchToFloorPlan",
			createNewLocation: "ops.locations.createNewLocation",
			editLayout: "ops.locations.editLayout",
			locationAvailable: "ops.locations.locationAvailable",
			addItem: "ops.locations.addItem",
			markedOccupied: "ops.locations.markedOccupied",
			markedAvailable: "ops.locations.markedAvailable",
			defaultOrderNote: "ops.locations.defaultOrderNote",
			editThisLocation: "ops.locations.editThisLocation",
			openConsumerApp: "ops.locations.openConsumerApp",
			getOrderQR: "ops.locations.getOrderQR",
			deleteThisLocation: "ops.locations.deleteThisLocation",
			totalVat: "ops.locations.totalVat",
			totalService: "ops.locations.totalService",
			totalBill: "ops.locations.totalBill",
			totalPaid: "ops.locations.totalPaid",
			totalUnpaid: "ops.locations.totalUnpaid",
			moveOrders: "ops.locations.moveOrders",
			moveItems: "ops.locations.moveItems",
			printChecklist: "ops.locations.printChecklist",
			split: "ops.locations.split",
			kitchenMessage: "ops.locations.kitchenMessage",
			sendKitchenMessage: "ops.locations.sendKitchenMessage",
			addObjectToLayout: "ops.locations.addObjectToLayout",
			doneEditLayout: "ops.locations.doneEditLayout",
			printBill: "ops.locations.printBill",
			selectNewLocation: "ops.locations.selectNewLocation",
			itemDisableMessage: "ops.locations.itemDisableMessage",
			itemTypeDisableMessage: "ops.locations.itemTypeDisableMessage",
			categoryDisableMessage: "ops.locations.categoryDisableMessage",
			payNowMustBeAllowedMessage: "ops.locations.payNowMustBeAllowedMessage",
			allowPayNow: "ops.locations.allowPayNow",
			allowPayLater: "ops.locations.allowPayLater",
			moveOrderOutOfPrefix: "ops.locations.moveOrderOutOfPrefix",
			moveOrderOutOfSuffix: "ops.locations.moveOrderOutOfSuffix",
			moveItemOutOfPrefix: "ops.locations.moveItemOutOfPrefix",
			moveItemOutOfSuffix: "ops.locations.moveItemOutOfSuffix",
			newLocationIdRequired: "ops.locations.newLocationIdRequired",
			allOrders: "ops.locations.allOrders",
			howDoYouWantToSplitThisTab: "ops.locations.howDoYouWantToSplitThisTab",
			equally: "ops.locations.equally",
			amoungHowManyPeopleWillThisTabBeSplitted:
				"ops.locations.amoungHowManyPeopleWillThisTabBeSplitted",
			eachPersonPays: "ops.locations.eachPersonPays",
			byItems: "ops.locations.byItems",
			selectItemsTosplitIntoNewOrders:
				"ops.locations.selectItemsTosplitIntoNewOrders",
			order: "ops.locations.order",
			unpaid: "ops.locations.unpaid",
			splitIntoNewOrder: "ops.locations.splitIntoNewOrder",
		},
		tills: {
			createItem: "ops.tills.createItem",
			itemType: "ops.tills.itemType",
			paymentMode: "ops.tills.paymentMode",
			changeToTab: "ops.tills.changeToTab",
			payNow: "ops.tills.payNow",
			paid: "ops.tills.paid",
			clear: "ops.tills.clear",
			options: "ops.tills.options",
			listedPrice: "ops.tills.listedPrice",
			itemComments: "ops.tills.itemComments",
			cancel: "ops.tills.cancel",
			save: "ops.tills.save",
			customer: "ops.tills.customer",
			kitchenMessage: "ops.tills.kitchenMessage",
			lowestDenominator: "ops.tills.lowestDenominator",
			headCount: "ops.tills.headCount",
			addHeadCount: "ops.tills.addHeadCount",
			selectPaymentType: "ops.tills.selectPaymentType",
			addTips: "ops.tills.addTips",
			paymentTypeUsed: "ops.tills.paymentTypeUsed",
			changeCalculator: "ops.tills.changeCalculator",
			totalRecieved: "ops.tills.totalRecieved",
			totalCashPayment: "ops.tills.totalCashPayment",
			change: "ops.tills.change",
			locationIsRequired: "ops.tills.locationIsRequired",
			optionGroups: "ops.tills.optionGroups",
			maximumSelect: "ops.tills.maximumSelect",
			required: "ops.tills.required",
			nonRequired: "ops.tills.nonRequired",
			grandTotal: "ops.tills.grandTotal",
			courseSeperator: "ops.tills.courseSeperator",
			showMore: "ops.tills.showMore",
			showLess: "ops.tills.showLess",
			cancelledThisItem: "ops.tills.cancelledThisItem",
			cancelledThisItemDesc: "ops.tills.cancelledThisItemDesc",
			tips: "ops.tills.tips",
			tipModelTitleBeforeAmount: "ops.tills.tipModelTitleBeforeAmount",
			tipModelTitleAfterAmount: "ops.tills.tipModelTitleAfterAmount",
			headcountRequiredBeforeTakePayment:
				"ops.tills.headcountRequiredBeforeTakePayment",
			invalidPaymentAmount: "ops.tills.invalidPaymentAmount",
			selectTip: "ops.tills.selectTip",
		},
	},
	menus: {
		menuManagement: "menus.menuManagement",
		menus: "menus.menus",
		createNewMenu: "menus.createNewMenu",
		updateMenu: "menus.updateMenu",
		logo: "menus.logo",
		name: "menus.name",
		description: "menus.description",
		visible: "menus.visible",
		view: "menus.view",
		edit: "menus.edit",
		viewItems: "menus.viewItems",
		viewOptions: "menus.viewOptions",
		copyExistingCategory: "menus.copyExistingCategory",
		copyThisCategory: "menus.copyThisCategory",
		createNewCategory: "menus.createNewCategory",
		copyFromExistingItem: "menus.copyFromExistingItem",
		copyThisItem: "menus.copyThisItem",
		createNewItem: "menus.createNewItem",
		selectACategoryFirstBeforeCloningItem:
			"menus.selectACategoryFirstBeforeCloningItem",
		categoryName: "menus.categoryName",
		price: "menus.price",
		type: "menus.type",
		category: "menus.category",
		pos: "menus.pos",
		digitalMenu: "menus.digitalMenu",
		categoryTitle: "menus.categoryTitle",
		categoryIsARequiredField: "menus.categoryIsARequiredField",
		titleIsARequiredField: "menus.titleIsARequiredField",
		displayInDigitalMenu: "menus.displayInDigitalMenu",
		displayInTill: "menus.displayInTill",
		printer: "menus.printer",
		printerTagIsARequiredField: "menus.printerTagIsARequiredField",
		typeIsARequiredField: "menus.typeIsARequiredField",
		title: "menus.title",
		originalPrice: "menus.originalPrice",
		prepTime: "menus.prepTime",
		extraQuantity: "menus.extraQuantity",
		listedPrice: "menus.listedPrice",
		addOption: "menus.addOption",
		copyOption: "menus.copyOption",
		copyThisOption: "menus.copyThisOption",
		copyOptionToItem: "menus.copyOptionToItem",
		addOptionGroup: "menus.addOptionGroup",
		copyOptionGroup: "menus.copyOptionGroup",
		addOptionGroupToItem: "menus.addOptionGroupToItem",
		copyOptionGroupToItem: "menus.copyOptionGroupToItem",
		copyThisOptionGroup: "menus.copyThisOptionGroup",
		newOptionsGroup: "menus.newOptionsGroup",
		copyFromExistingOptionGroup: "menus.copyFromExistingOptionGroup",
		copyFromExistingOptions: "menus.copyFromExistingOptions",
		optionGroupName: "menus.optionGroupName",
		updateOptionGroup: "menus.updateOptionGroup",
		addFromExistingOption: "menus.addFromExistingOption",
		removeThisOption: "menus.removeThisOption",
		optionTitle: "menus.optionTitle",
		optionName: "menus.optionName",
		optionPrice: "menus.optionPrice",
		duplicateOptionName: "menus.duplicateOptionName",
		duplicateOptionGroupName: "menus.duplicateOptionGroupName",
		youAreAboutToDeleteThisOption: "menus.youAreAboutToDeleteThisOption",
		updateItem: "menus.updateItem",
		itemOptions: "menus.itemOptions",
		itemOptionGroups: "menus.itemOptionGroups",
		manageItemRecipe: "menus.manageItemRecipe",
		selectOptions: "menus.selectOptions",
		selectOptionGroups: "menus.selectOptionGroups",
		updateRecipeToMenuOption: "menus.updateRecipeToMenuOption",
		updateItemOptionGroup: "menus.updateItemOptionGroup",
		requiredOption: "menus.requiredOption",
		minimumSelect: "menus.minimumSelect",
		maximumSelect: "menus.maximumSelect",
		addThisOption: "menus.addThisOption",
		newOption: "menus.newOption",
		createNewOptionGroup: "menus.createNewOptionGroup",
		createNewOption: "menus.createNewOption",
		updateOption: "menus.updateOption",
		optionGroupTitle: "menus.optionGroupTitle",
		vat: "menus.vat",
		serviceCharge: "menus.serviceCharge",
		autoSelectOption: "menus.autoSelectOption",
		minimumRequiredPrice: "menus.minimumRequiredPrice",
		itemId: "menus.itemId",
		optionId: "menus.optionId",
		hideFromReceipt: "menus.hideFromReceipt",
		isHideFromReceipt: "menus.isHideFromReceipt",
		action: "menus.action",
	},
	reports: {
		nav: {
			shift: "reports.summary.shift",
			from: "reports.summary.from",
			to: "reports.summary.to",
			generate: "reports.summary.generate",
			export: "reports.summary.export",
			summary: "reports.summary.summary",
			customs: "reports.summary.customs",
			receipts: "reports.summary.receipts",
			categories: "reports.summary.categories",
			items: "reports.summary.items",
			options: "reports.summary.options",
			locations: "reports.summary.locations",
			staffs: "reports.summary.staffs",
			details: "reports.summary.details",
			inventory: "reports.summary.inventory",
		},
		summary: {
			salesSummary: "reports.summary.salesSummary",
			tableView: "reports.summary.tableView",
			chartView: "reports.summary.chartView",
			subtotalPrice: "reports.summary.subtotalPrice",
			discount: "reports.summary.discount",
			offers: "reports.summary.offers",
			netSales: "reports.summary.netSales",
			serviceCharge: "reports.summary.serviceCharge",
			vat: "reports.summary.vat",
			grossSales: "reports.summary.grossSales",
			cancelled: "reports.summary.cancelled",
			roundingAmount: "reports.summary.roundingAmount",
			paymentsReceived: "reports.summary.paymentsReceived",
			salesByDays: "reports.summary.salesByDays",
			columns: "reports.summary.columns",
			filters: "reports.summary.filters",
			density: "reports.summary.density",
			findColumn: "reports.summary.findColumn",
			columnTitle: "reports.summary.columnTitle",
			hideAll: "reports.summary.hideAll",
			showAll: "reports.summary.showAll",
			operator: "reports.summary.operator",
			value: "reports.summary.value",
			is: "reports.summary.is",
			isNot: "reports.summary.isNot",
			isAfter: "reports.summary.isAfter",
			isOnOrAfter: "reports.summary.isOnOrAfter",
			isBefore: "reports.summary.isBefore",
			isOnOrBefore: "reports.summary.isOnOrBefore",
			clear: "reports.summary.clear",
			today: "reports.summary.today",
			equals: "reports.summary.equals",
			notEqual: "reports.summary.notEqual",
			greaterThan: "reports.summary.greaterThan",
			greaterThanOrEqual: "reports.summary.greaterThanOrEqual",
			lessThan: "reports.summary.lessThan",
			lessThanOrEqual: "reports.summary.lessThanOrEqual",
			isAnyOf: "reports.summary.isAnyOf",
			date: "reports.summary.date",
			offerAmount: "reports.summary.offerAmount",
			discountAmount: "reports.summary.discountAmount",
			adjustment: "reports.summary.adjustment",
			cancelledAmount: "reports.summary.cancelledAmount",
			avgOrderAmount: "reports.summary.avgOrderAmount",
			receiptsCount: "reports.summary.receiptsCount",
			averageReceiptAmount: "reports.summary.averageReceiptAmount",
			compact: "reports.summary.compact",
			standard: "reports.summary.standard",
			comfortable: "reports.summary.comfortable",
			downloadAsCsv: "reports.summary.downloadAsCsv",
			print: "reports.summary.print",
			beforeYouProceed: "reports.summary.beforeYouProceed",
			processTakesAwhileMessage: "reports.summary.processTakesAwhileMessage",
			printerForReportIsAvailable:
				"reports.summary.printerForReportIsAvailable",
			setupHere: "reports.summary.setupHere",
			printerForReportIsNotAvailable:
				"reports.summary.printerForReportIsNotAvailable",
			printServiceIsOnlineMessage:
				"reports.summary.printServiceIsOnlineMessage",
			printServiceIsOfflineMessage:
				"reports.summary.printServiceIsOfflineMessage",
			selectTheTypeOfReportsForExport:
				"reports.summary.selectTheTypeOfReportsForExport",
			salesSummaryReports: "reports.summary.salesSummaryReports",
			itemsSalesReports: "reports.summary.itemsSalesReports",
			printOnlyItemsWithSales: "reports.summary.printOnlyItemsWithSales",
			paymentTypes: "reports.summary.paymentTypes",
			locationGroupTags: "reports.summary.locationGroupTags",
			downloadSoftCopy: "reports.summary.downloadSoftCopy",
		},
		custom: {
			newCustomReportCard: "reports.custom.newCustomReportCard",
			updateCustomReportCard: "reports.custom.updateCustomReportCard",
			cardHeader: "reports.custom.cardHeader",
			chartType: "reports.custom.chartType",
			configuration: "reports.custom.configuration",
			label: "reports.custom.label",
			salesValue: "reports.custom.salesValue",
			dataType: "reports.custom.dataType",
			dataPoint: "reports.custom.dataPoint",
			addDataPoint: "reports.custom.addDataPoint",
			bulkAddDataPoint: "reports.custom.bulkAddDataPoint",
			youAreAboutToDeleteThisCustomReport:
				"reports.custom.youAreAboutToDeleteThisCustomReport",
			id: "reports.custom.id",
			title: "reports.custom.title",
			unitPrice: "reports.custom.unitPrice",
			count: "reports.custom.count",
			subtotal: "reports.custom.subtotal",
			discount: "reports.custom.discount",
			offer: "reports.custom.offer",
			netSales: "reports.custom.netSales",
			serviceCharge: "reports.custom.serviceCharge",
			vat: "reports.custom.vat",
			grossSales: "reports.custom.grossSales",
			cancelledValue: "reports.custom.cancelledValue",
		},
		receipts: {
			avgPaymentPerReceipt: "reports.receipts.avgPaymentPerReceipt",
			receiptCount: "reports.receipts.receiptCount",
			nonVoidReceipt: "reports.receipts.nonVoidReceipt",
			voidReceipt: "reports.receipts.voidReceipt",
			byReceiptTypes: "reports.receipts.byReceiptTypes",
			internalReceipts: "reports.receipts.internalReceipts",
			externalReceipts: "reports.receipts.externalReceipts",
			allBlank: "reports.receipts.allBlank",
			addFilter: "reports.receipts.addFilter",
			year: "reports.receipts.year",
			month: "reports.receipts.month",
			date: "reports.receipts.date",
			hour: "reports.receipts.hour",
			minute: "reports.receipts.minute",
			receipt: "reports.receipts.receipt",
			orders: "reports.receipts.orders",
			locationTypes: "reports.receipts.locationTypes",
			locationName: "reports.receipts.locationName",
			staff: "reports.receipts.staff",
			status: "reports.receipts.status",
			subtotalPrice: "reports.receipts.subtotalPrice",
			offerName: "reports.receipts.offerName",
			offerAmount: "reports.receipts.offerAmount",
			discountAmount: "reports.receipts.discountAmount",
			netSales: "reports.receipts.netSales",
			vat: "reports.receipts.vat",
			serviceCharge: "reports.receipts.serviceCharge",
			grossAmount: "reports.receipts.grossAmount",
			roundingAmt: "reports.receipts.roundingAmt",
			adjustmentAmt: "reports.receipts.adjustmentAmt",
			cancelledAmount: "reports.receipts.cancelledAmount",
			paymentTypes: "reports.receipts.paymentTypes",
			paymentsReceived: "reports.receipts.paymentsReceived",
			customers: "reports.receipts.customers",
			headcount: "reports.receipts.headcount",
			tips: "reports.receipts.tips",
			shift: "reports.receipts.shift",
			paywayrefs: "reports.receipts.paywayrefs",
			kpayPaymentTypes: "reports.receipts.kpayPaymentTypes",
		},
		categories: {
			total: "reports.categories.total",
			top10ByRevenue: "reports.categories.top10ByRevenue",
			top10BySoldItems: "reports.categories.top10BySoldItems",
			categoryName: "reports.categories.categoryName",
			soldItems: "reports.categories.soldItems",
			revenue: "reports.categories.revenue",
			title: "reports.categories.title",
			subtotal: "reports.categories.subtotal",
			discount: "reports.categories.discount",
			offer: "reports.categories.offer",
			netSales: "reports.categories.netSales",
			serviceCharge: "reports.categories.serviceCharge",
			vat: "reports.categories.vat",
			grossSales: "reports.categories.grossSales",
			cancelledValue: "reports.categories.cancelledValue",
			sort: "reports.categories.sort",
			unsort: "reports.categories.unsort",
			sortByAsc: "reports.categories.sortByAsc",
			sortByDesc: "reports.categories.sortByDesc",
			filters: "reports.categories.filters",
			hideAll: "reports.categories.hideAll",
			showColumns: "reports.categories.showColumns",
			pinToLeft: "reports.categories.pinToLeft",
			pinToRight: "reports.categories.pinToRight",
		},
		items: {
			total: "reports.items.total",
			revenueByItemTypes: "reports.items.revenueByItemTypes",
			soldItemsByItemTypes: "reports.items.soldItemsByItemTypes",
			itemType: "reports.items.itemType",
			revenue: "reports.items.revenue",
			itemTitle: "reports.items.itemTitle",
			optionTitle: "reports.items.optionTitle",
			categoryTitle: "reports.items.categoryTitle",
			subtotal: "reports.items.subtotal",
			netAmount: "reports.items.netAmount",
			discount: "reports.items.discount",
			soldItem: "reports.items.soldItem",
			offer: "reports.items.offer",
			serviceCharge: "reports.items.serviceCharge",
			vat: "reports.items.vat",
			grossSales: "reports.items.grossSales",
			cancelledValue: "reports.items.cancelledValue",
			itemId: "reports.items.itemId",
			top10ByRevenue: "reports.items.top10ByRevenue",
			top10BySoldItems: "reports.items.top10BySoldItems",
		},
		options: {
			total: "reports.options.total",
			revenue: "reports.options.revenue",
			optionTitle: "reports.options.optionTitle",
			categoryTitle: "reports.options.categoryTitle",
			subtotal: "reports.options.subtotal",
			netAmount: "reports.options.netAmount",
			discount: "reports.options.discount",
			soldOption: "reports.options.soldOption",
			offer: "reports.options.offer",
			serviceCharge: "reports.options.serviceCharge",
			vat: "reports.options.vat",
			grossSales: "reports.options.grossSales",
			cancelledValue: "reports.options.cancelledValue",
			optionId: "reports.options.optionId",
			top10ByRevenue: "reports.options.top10ByRevenue",
			top10BySoldItems: "reports.options.top10BySoldItems",
		},
		locations: {
			mostPopular: "reports.locations.mostPopular",
			topMoneyMaker: "reports.locations.topMoneyMaker",
			receivedPaymentsAll: "reports.locations.receivedPaymentsAll",
			salesByLocationGroupAll: "reports.locations.salesByLocationGroupAll",
			locationTypes: "reports.locations.locationTypes",
			paymentTypes: "reports.locations.paymentTypes",
			showingAllLocations: "reports.locations.showingAllLocations",
			onlyXLocations: "reports.locations.onlyXLocations",
			locationNames: "reports.locations.locationNames",
			ordersCount: "reports.locations.ordersCount",
			receiptCount: "reports.locations.receiptCount",
			headcount: "tokens.reports.locations.headcount",
			receiptsAmount: "reports.locations.receiptsAmount",
			subtotalPrice: "reports.locations.subtotalPrice",
			offerAmount: "reports.locations.offerAmount",
			discountAmount: "reports.locations.discountAmount",
			netSales: "reports.locations.netSales",
			vatAmount: "reports.locations.vatAmount",
			serviceChargeAmount: "reports.locations.serviceChargeAmount",
			grossAmount: "reports.locations.grossAmount",
			roundingAmt: "reports.locations.roundingAmt",
			adjustmentAmount: "reports.locations.adjustmentAmount",
			cancelledAmount: "reports.locations.cancelledAmount",
			byPaymentTypes: "reports.locations.byPaymentTypes",
		},
		staffs: {
			topPerformerByOrdersCount: "reports.staffs.topPerformerByOrdersCount",
			ordersCount: "reports.staffs.ordersCount",
			ordersGrossSales: "reports.staffs.ordersGrossSales",
			topPerformerByOrdersGrossSales:
				"reports.staffs.topPerformerByOrdersGrossSales",
			staffName: "reports.staffs.staffName",
		},
		details: {
			grossAmount: "reports.details.grossAmount",
			qr: "reports.details.qr",
			till: "reports.details.till",
			averageOrderAmount: "reports.details.averageOrderAmount",
			ordersCount: "reports.details.ordersCount",
			year: "reports.details.year",
			month: "reports.details.month",
			date: "reports.details.date",
			hour: "reports.details.hour",
			minute: "reports.details.minute",
			receipt: "reports.details.receipt",
			orders: "reports.details.orders#",
			locationTypes: "reports.details.locationTypes",
			locationName: "reports.details.locationName",
			orderThrough: "reports.details.orderThrough",
			staffName: "reports.details.staffName",
			category: "reports.details.category",
			itemType: "reports.details.itemType",
			item: "reports.details.item",
			status: "reports.details.status",
			isPaid: "reports.details.isPaid",
			isCancelled: "reports.details.isCancelled",
			qty: "reports.details.qty",
			unitPrice: "reports.details.unitPrice",
			subtotalPrice: "reports.details.subtotalPrice",
			offerName: "reports.details.offerName",
			offerAmount: "reports.details.offerAmount",
			discountAmount: "reports.details.discountAmount",
			netSales: "reports.details.netSales",
			vatIncluded: "reports.details.vatIncluded",
			scIncluded: "reports.details.scIncluded",
			adjustmentAmount: "reports.details.adjustmentAmount",
			cancelledAmount: "reports.details.cancelledAmount",
			cancelledReason: "reports.details.cancelledReason",
			finalPaymentType: "reports.details.finalPaymentType",
			customers: "reports.details.customers",
			shift: "reports.details.shift",
			paywayRef: "reports.details.paywayRef",
			itemId: "reports.details.itemId",
		},
		inventory: {
			liveData: "reports.inventory.liveData",
			historicalData: "reports.inventory.historicalData",
			totalInventoryBalanceValue:
				"reports.inventory.totalInventoryBalanceValue",
			top10MostUtilisedRawMaterialsBalanceValue:
				"reports.inventory.top10MostUtilisedRawMaterialsBalanceValue",
			top10LeastUtilisedRawMaterialsBalanceValue:
				"reports.inventory.top10LeastUtilisedRawMaterialsBalanceValue",
			top10MostUtilised: "reports.inventory.top10MostUtilised",
			top10LeastUtilised: "reports.inventory.top10LeastUtilised",
			name: "reports.inventory.name",
			balanceQty: "reports.inventory.balanceQty",
			balanceValue: "reports.inventory.balanceValue",
			rawMaterialName: "reports.inventory.rawMaterialName",
			rawMaterialCode: "reports.inventory.rawMaterialCode",
			receivedUom: "reports.inventory.receivedUom",
			totalReceivedQty: "reports.inventory.totalReceivedQty",
			totalConsumedQty: "reports.inventory.totalConsumedQty",
			totalWastageQty: "reports.inventory.totalWastageQty",
			totalDefectQty: "reports.inventory.totalDefectQty",
			totalExpiredQty: "reports.inventory.totalExpiredQty",
			totalAdjustmentQty: "reports.inventory.totalAdjustmentQty",
			balanceQuantity: "reports.inventory.balanceQuantity",
			avgCostPerUom: "reports.inventory.avgCostPerUom",
			supplierName: "reports.inventory.supplierName",
			storageLocation: "reports.inventory.storageLocation",
		},
	},
	receipts: {
		landing: {
			dates: "receipts.landing.dates",
			ordersUseForMultiples: "receipts.landing.ordersUseForMultiples",
			receiptsUseForMultiples: "receipts.landing.receiptsUseForMultiples",
			receipt: "receipts.landing.receipt",
			createdAt: "receipts.landing.createdAt",
			location: "receipts.landing.location",
			paymentType: "receipts.landing.paymentType",
			description: "receipts.landing.description",
			actions: "receipts.landing.actions",
			reprint: "receipts.landing.reprint",
			voidReceipt: "receipts.landing.voidReceipt",
		},
		expandReceiptDetails: {
			receipt: "receipts.expandReceiptDetails.receipt",
			ordersNumbers: "receipts.expandReceiptDetails.ordersNumbers",
			OfPeople: "receipts.expandReceiptDetails.OfPeople",
			location: "receipts.expandReceiptDetails.location",
			printedDate: "receipts.expandReceiptDetails.printedDate",
			orderedDate: "receipts.expandReceiptDetails.orderedDate",
			subtotal: "receipts.expandReceiptDetails.subtotal",
			offer: "receipts.expandReceiptDetails.offer",
			discount: "receipts.expandReceiptDetails.discount",
			vat: "receipts.expandReceiptDetails.vat",
			serviceCharge: "receipts.expandReceiptDetails.serviceCharge",
			adjustmentValue: "receipts.expandReceiptDetails.adjustmentValue",
			grandTotal: "receipts.expandReceiptDetails.grandTotal",
			roundingDifference: "receipts.expandReceiptDetails.roundingDifference",
			paymentTypes: "receipts.expandReceiptDetails.paymentTypes",
			cashReceived: "receipts.expandReceiptDetails.cashReceived",
			cashChanges: "receipts.expandReceiptDetails.cashChanges",
			cancelledAmount: "receipts.expandReceiptDetails.cancelledAmount",
			paymentNotEqualToGrandTotal:
				"receipts.expandReceiptDetails.paymentNotEqualToGrandTotal",
			cancelledItems: "receipts.expandReceiptDetails.cancelledItems",
			addPaymentType: "receipts.expandReceiptDetails.addPaymentType",
		},
	},
	orders: {
		landing: {
			dates: "orders.landing.dates",
			ordersUseForMultiples: "orders.landing.ordersUseForMultiples",
			createdAt: "orders.landing.createdAt",
			paymentStatus: "orders.landing.paymentStatus",
			description: "orders.landing.description",
			orderStatus: "orders.landing.orderStatus",
			copyThisLogId: "orders.landing.copyThisLogId",
			placed: "orders.landing.placed",
			preparing: "orders.landing.preparing",
			processed: "orders.landing.processed",
		},
	},
	customers: {
		landing: {
			searchPlaceholder: "customers.landing.searchPlaceholder",
			newCustomer: "customers.landing.newCustomer",
			creatingNewCustomer: "customers.landing.creatingNewCustomer",
			formMessage: "customers.landing.formMessage",
			firstName: "customers.landing.firstName",
			firstNameIsRequired: "customers.landing.firstNameIsRequired",
			lastName: "customers.landing.lastName",
			lastNameIsRequired: "customers.landing.lastNameIsRequired",
			phoneNumber: "customers.landing.phoneNumber",
			uniqueId: "customers.landing.uniqueId",
			socials: "customers.landing.socials",
			createdAt: "customers.landing.createdAt",
			fullName: "customers.landing.fullName",
		},
		customerDetails: {
			customerInfo: "customers.customerDetails.CustomerInfo",
			orderHistory: "customers.customerDetails.orderHistory",
			firstName: "customers.customerDetails.firstName",
			lastName: "customers.customerDetails.lastName",
			phoneNumber: "customers.customerDetails.phoneNumber",
			uniqueId: "customers.customerDetails.uniqueId",
			dates: "customers.customerDetails.dates",
			items: "customers.customerDetails.items",
			socials: "customers.customerDetails.socials",
			save: "customers.customerDetails.save",
			salesSummary: "customers.customerDetails.salesSummary",
			grossAmount: "customers.customerDetails.grossAmount",
			ordersCount: "customers.customerDetails.ordersCount",
			discount: "customers.customerDetails.discount",
			columns: "customers.customerDetails.columns",
			title: "customers.customerDetails.title",
			soldCount: "customers.customerDetails.soldCount",
			hideAll: "customers.customerDetails.hideAll",
			showAll: "customers.customerDetails.showAll",
			filters: "customers.customerDetails.filters",
			operator: "customers.customerDetails.operator",
			value: "customers.customerDetails.value",
			contains: "customers.customerDetails.contains",
			equals: "customers.customerDetails.equals",
			startsWith: "customers.customerDetails.startsWith",
			endsWith: "customers.customerDetails.endsWith",
			isEmpty: "customers.customerDetails.isEmpty",
			isNotEmpty: "customers.customerDetails.isNotEmpty",
			isAnyOf: "customers.customerDetails.isAnyOf",
			density: "customers.customerDetails.density",
			compact: "customers.customerDetails.compact",
			standard: "customers.customerDetails.standard",
			comfortable: "customers.customerDetails.comfortable",
			export: "customers.customerDetails.export",
			downloadAsCsv: "customers.customerDetails.downloadAsCsv",
			print: "customers.customerDetails.print",
			sortByAsc: "customers.customerDetails.sortByAsc",
			sortByDesc: "customers.customerDetails.sortByDesc",
			showColumns: "customers.customerDetails.showColumns",
			pinToLeft: "customers.customerDetails.pinToLeft",
			pinToRight: "customers.customerDetails.pinToRight",
			noResultsFound: "customers.customerDetails.noResultsFound.",
			rowsPerPage: "customers.customerDetails.rowsPerPage",
			createdAt: "customers.customerDetails.createdAt",
			paymentStatus: "customers.customerDetails.paymentStatus",
			description: "customers.customerDetails.description",
			orderStatus: "customers.customerDetails.orderStatus",
		},
	},
	offers: {
		landing: {
			title: "offers.landing.title",
			newOffer: "offers.landing.newOffer",
			createdAt: "offers.landing.createdAt",
			summary: "offers.landing.summary",
			creatingNewOffer: "offers.landing.creatingNewOffer",
			formMessage: "offers.landing.formMessage",
			offerTitle: "offers.landing.offerTitle",
			tileIsRequired: "offers.landing.tileIsRequired",
			applyHigherValue: "offers.landing.applyHigherValue",
			applyHighestValueMessage: "offers.landing.applyHighestValueMessage",
			applyLowestValueMessage: "offers.landing.applyLowestValueMessage",
			addConfig: "offers.landing.addConfig",
			byReceipts: "offers.landing.byReceipts",
			useWithOtherConfigTypes: "offers.landing.useWithOtherConfigTypes",
			byItems: "offers.landing.byItems",
			byCategories: "offers.landing.byCategories",
			byItemTypes: "offers.landing.byItemTypes",
			discountByReceipts: "offers.landing.discountByReceipts",
			discountByItems: "offers.landing.discountByItems",
			applicableItems: "offers.landing.applicableItems",
			discountByCategories: "offers.landing.discountByCategories",
			applicableCategories: "offers.landing.applicableCategories",
			discountValue: "offers.landing.discountValue",
			discountByItemTypes: "offers.landing.discountByItemTypes",
			applicableItemTypes: "offers.landing.applicableItemTypes",
		},
		offerDetails: {
			offerInfo: "offers.offerDetails.offerInfo",
			offerName: "offers.offerDetails.offerName",
			offerStatus: "offers.offerDetails.offerStatus",
			active: "offers.offerDetails.active",
			configSummary: "offers.offerDetails.configSummary",
			offerHigherValueUponCollision:
				"offers.offerDetails.offerHigherValueUponCollision",
			offerLowerValueUponCollision:
				"offers.offerDetails.offerLowerValueUponCollision",
			editThisOffer: "offers.offerDetails.editThisOffer",
			deactivate: "offers.offerDetails.deactivate",
			offerUsageSummary: "offers.offerDetails.offerUsageSummary",
			ordersCount: "offers.offerDetails.ordersCount",
			usageAmount: "offers.offerDetails.usageAmount",
			ordersHistory: "offers.offerDetails.ordersHistory",
			dates: "offers.offerDetails.dates",
			createdAt: "offers.offerDetails.createdAt",
			paymentStatus: "offers.offerDetails.paymentStatus",
			description: "offers.offerDetails.description",
			orderStatus: "offers.offerDetails.orderStatus",
			copyThisLogId: "offers.offerDetails.copyThisLogId",
			criterias: "offers.offerDetails.criterias",
			updatingOffer: "offers.offerDetails.updatingOffer",
			formHeaderDesc: "offers.offerDetails.formHeaderDesc",
			youCanTUseThisWithOtherConfigTypes:
				"offers.offerDetails.youCanTUseThisWithOtherConfigTypes",
		},
	},
	settings: {
		nav: {
			general: "settings.nav.general",
			ordering: "settings.nav.ordering",
			printing: "settings.nav.printing",
			customs: "settings.nav.customs",
			userPermissions: "settings.nav.userPermissions",
			addOns: "settings.nav.addOns",
		},
		general: {
			name: "settings.general.name",
			description: "settings.general.description",
			address: "settings.general.address",
			vatTin: "settings.general.vatTin",
		},
		ordering: {
			requireCustomerForDigitalMenuOrders:
				"settings.ordering.requireCustomerForDigitalMenuOrders",
			enableOrderCommentsForDigitalMenuOrders:
				"settings.ordering.enableOrderCommentsForDigitalMenuOrders",
			digitalMenuLabel: "settings.ordering.digitalMenuLabel",
			activateDoubleScreen: "settings.ordering.activateDoubleScreen",
			noteLabel: "settings.ordering.noteLabel",
			noteInstructionLabel: "settings.ordering.noteInstructionLabel",
			inputTheNoteInstructions: "settings.ordering.inputTheNoteInstructions",
			orderNotificationDings: "settings.ordering.orderNotificationDings",
			orderNotificationDingsHelper:
				"settings.ordering.orderNotificationDingsHelper",
			addVat: "settings.ordering.addVat",
			howVatShouldBeCharged: "settings.ordering.howVatShouldBeCharged",
			separatelyAsLineItem: "settings.ordering.separatelyAsLineItem",
			vatShowMessage: "settings.ordering.vatShowMessage",
			vatBuriedMessage: "settings.ordering.vatBuriedMessage",
			addServiceCharge: "settings.ordering.addServiceCharge",
			howMuchForServiceCharge: "settings.ordering.howMuchForServiceCharge",
			serviceFeeNote: "settings.ordering.serviceFeeNote",
			howServiceShouldCharged: "settings.ordering.howServiceShouldCharged",
			buriedInItemsAndOptionsPrice:
				"settings.ordering.buriedInItemsAndOptionsPrice",
			serviceChargeShowMessage: "settings.ordering.serviceChargeShowMessage",
			serviceChargeBuriedMessage:
				"settings.ordering.serviceChargeBuriedMessage",
			opsPageFontSize: "settings.ordering.opsPageFontSize",
			opsPageFontsSizePx: "settings.ordering.opsPageFontsSizePx",
		},
		printing: {
			printServiceStatus: "settings.printing.printServiceStatus",
			printServiceStatusMessage: "settings.printing.printServiceStatusMessage",
			printOrderChecklist: "settings.printing.printOrderChecklist",
			printTicketByItem: "settings.printing.printTicketByItem",
			printTicketByOption: "settings.printing.printTicketByOption",
			isGroupingOptionOnPrintTicket:
				"settings.printing.isGroupingOptionOnPrintTicket",
			printContentPaddingPixels: "settings.printing.printContentPaddingPixels",
			topPx: "settings.printing.topPx",
			rightPx: "settings.printing.rightPx",
			bottomPx: "settings.printing.bottomPx",
			leftPx: "settings.printing.leftPx",
			printFontSizesPixels: "settings.printing.printFontSizesPixels",
			ticketChecklistMessagePx: "settings.printing.ticketChecklistMessage",
			billReceiptPx: "settings.printing.billReceiptPx",
			reportsPx: "settings.printing.reportsPx",
			createNewStation: "settings.printing.createNewStation",
			createNewWorkStation: "settings.printing.createNewWorkStation",
			updateWorkStation: "settings.printing.updateWorkStation",
			whatShouldBeTheStationName:
				"settings.printing.whatShouldBeTheStationName",
			selectAPrinterForThisStation:
				"settings.printing.selectAPrinterForThisStation",
			itemTypesOfInterest: "settings.printing.itemTypesOfInterest",
			printerTagsOfInterest: "settings.printing.printerTagsOfInterest",
			locationsOfInterest: "settings.printing.locationsOfInterest",
			cancel: "settings.printing.cancel",
			save: "settings.printing.save",
			stationName: "settings.printing.stationName",
			usingPrinter: "settings.printing.usingPrinter",
			manage: "settings.printing.manage",
			kitchenMessage: "settings.printing.kitchenMessage",
			ticket: "settings.printing.ticket",
			receipt: "settings.printing.receipt",
			checklist: "settings.printing.checklist",
			report: "settings.printing.report",
			cancelledOrderTicket: "settings.printing.cancelledOrderTicket",
			delete: "settings.printing.delete",
		},
		customs: {
			customPrintingFields: "settings.customs.customPrintingFields",
			typeAndPressEnter: "settings.customs.typeAndPressEnter",
			availableItemTypes: "settings.customs.availableItemTypes",
			customPaymentTypes: "settings.customs.customPaymentTypes",
			shifts: "settings.customs.shifts",
			locationGroupTags: "settings.customs.locationGroupTags",
			printerTagsOfInterest: "settings.customs.printerTagsOfInterest",
			orderCancelReasons: "settings.customs.orderCancelReasons",
			presetMessages: "settings.customs.presetMessages",
			semicolonIsAReservedCharacter:
				"settings.customs.semicolonIsAReservedCharacter",
			currenciesConfiguration: "settings.customs.currenciesConfiguration",
			currencyCode: "settings.customs.currencyCode",
			rateToPrimaryCurrency: "settings.customs.rateToPrimaryCurrency",
			primary: "settings.customs.primary",
			secondary: "settings.customs.secondary",
			deactivate: "settings.customs.deactivate",
			addCurrency: "settings.customs.addCurrency",
			createCurrencyConfiguration:
				"settings.customs.createCurrencyConfiguration",
			rateComparedToPrimaryCurrency:
				"settings.customs.rateComparedToPrimaryCurrency",
			receiptFormat: "settings.customs.receiptFormat",
			receiptCurrentCounter: "settings.customs.receiptCurrentCounter",
			defaultOpsPage: "settings.customs.defaultOpsPage",
			printerTags: "settings.customs.printerTags",
		},
		userPermissions: {
			searchUsername: "settings.userPermissions.searchUsername",
			reFetchUsers: "settings.userPermissions.reFetchUsers",
			ORDER_MANAGEMENT_FEATURE:
				"settings.userPermissions.ORDER_MANAGEMENT_FEATURE",
			VIEW_ORDER: "settings.userPermissions.VIEW_ORDER",
			CREATE_ORDER: "settings.userPermissions.CREATE_ORDER",
			EDIT_ORDER_BEFORE_BILL_PRINTED:
				"settings.userPermissions.EDIT_ORDER_BEFORE_BILL_PRINTED",
			EDIT_ORDER_AFTER_BILL_PRINTED:
				"settings.userPermissions.EDIT_ORDER_AFTER_BILL_PRINTED",
			EDIT_ORDER_BEFORE_PAYMENT:
				"settings.userPermissions.EDIT_ORDER_BEFORE_PAYMENT",
			EDIT_ORDER_AFTER_PAYMENT:
				"settings.userPermissions.EDIT_ORDER_AFTER_PAYMENT",
			CANCEL_ORDER_BEFORE_BILL_PRINTED:
				"settings.userPermissions.CANCEL_ORDER_BEFORE_BILL_PRINTED",
			CANCEL_ORDER_AFTER_BILL_PRINTED:
				"settings.userPermissions.CANCEL_ORDER_AFTER_BILL_PRINTED",
			CANCEL_ORDER_BEFORE_PAYMENT:
				"settings.userPermissions.CANCEL_ORDER_BEFORE_PAYMENT",
			CANCEL_ORDER_AFTER_PAYMENT:
				"settings.userPermissions.CANCEL_ORDER_AFTER_PAYMENT",
			RECEIPT_MANAGEMENT: "settings.userPermissions.RECEIPT_MANAGEMENT",
			CANCEL_RECEIPT: "settings.userPermissions.CANCEL_RECEIPT",
			USER_SETTINGS: "settings.userPermissions.USER_SETTINGS",
			OPEN_CASHDRAWER: "settings.userPermissions.OPEN_CASHDRAWER",
			AUTO_LOGOUT: "settings.userPermissions.AUTO_LOGOUT",
		},
		addOns: {
			enableCustomReceiptFormat: "settings.addOns.enableCustomReceiptFormat",
			shouldAggregateTipsIntoTotalGrossSales:
				"settings.addOns.shouldAggregateTipsIntoTotalGrossSales",
			consumerAppTheme: "settings.addOns.consumerAppTheme",
			primaryColor: "settings.addOns.primaryColor",
			primaryTextColor: "settings.addOns.primaryTextColor",
			enableInventoryMangement: "settings.addOns.enableInventoryMangement",
			enablePurchaseManagement: "settings.addOns.enablePurchaseManagement",
			enablePaymentRounding: "settings.addOns.enablePaymentRounding",
			venueOnlyUseDigitalMenu: "settings.addOns.venueOnlyUseDigitalMenu",
			paywayLabel: "settings.addOns.paywayLabel",
			kpayLabel: "settings.addOns.kpayLabel",
			enableInternalReceipt: "settings.addOns.enableInternalReceipt",
			enableLanguageLocalization: "settings.addOns.enableLanguageLocalization",
			requireHeadcountBeforePayment:
				"settings.addOns.requireHeadcountBeforePayment",
			opsIntervalLabel: "settings.addOns.opsIntervalLabel",
			enableRoomMasterIntegration:
				"settings.addOns.enableRoomMasterIntegration",
			setUpRoomMasterPropertyId: "settings.addOns.setUpRoomMasterPropertyId",
			enableCustomReport: "settings.addOns.enableCustomReport",
			enableDualScreenMode: "settings.addOns.enableDualScreenMode",
			yes: "settings.addOns.yes",
			no: "settings.addOns.no",
		},
	},
	printservice: {
		staff: "printservices.staff",
		print: "printservices.print",
		ordered: "printservices.ordered",
		orders: "printservices.orders",
		qty: "printservices.qty",
		item: "printservices.item",
		price: "printservices.price",
		subtotal: "printservices.subtotal",
		vat: "printservices.vat",
		serviceCharge: "printservices.serviceCharge",
		grandTotal: "printservices.grandTotal",
		receipt: "printservices.receipt",
		paymentTypes: "printservices.paymentTypes",
		cashReceived: "printservices.cashReceived",
		cashChanges: "printservices.cashChanges",
		received: "printservices.received",
	},
	global: {
		bill: "global.bill",
		location: "global.location",
		orders: "global.orders",
		placed: "global.placed",
		preparing: "global.preparing",
		processed: "global.processed",
		proceed: "global.proceed",
		unpaid: "global.unpaid",
		paid: "global.paid",
		pay: "global.pay",
		payNow: "global.payNow",
		payLater: "global.payLater",
		move: "global.move",
		of: "global.of",
		not: "global.not",
		allowed: "global.allowed",
		all: "global.all",
		view: "global.view",
		details: "gloval.details",
		add: "global.add",
		create: "global.create",
		edit: "global.edit",
		update: "global.update",
		save: "global.save",
		yes: "global.yes",
		no: "global.no",
		delete: "global.delete",
		send: "global.send",
		undo: "global.undo",
		back: "global.back",
		cancel: "global.cancel",
		close: "global.closed",
		done: "global.done",
		cancelled: "global.cancelled",
		subTotal: "global.subTotal",
		offer: "global.offer",
		discount: "global.discount",
		vat: "global.vat",
		service: "global.service",
		adjustment: "global.adjustment",
		grandTotal: "global.grandTotal",
		presetMessages: "global.presetMessages",
		logout: "global.logout",
		copy: "global.copy",
		manage: "global.manage",
		addThisOption: "global.addThisOption",
		cancelReason: "global.cancelReason",
		cancelReasonIsRequired: "global.cancelReasonIsRequired",
		createAndReturnToTill: "global.createAndReturnToTill",
		reset: "global.reset",
		newerAppVersionAvailable: "global.newerAppVersionAvailable",
		reloadPage: "global.reloadPage",
	},
};
